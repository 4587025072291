<template>
  <div id="wrapper" class="relative font-Gilroy">
    <div class="border-t border-gray-500">
      <div class="flex justify-center my-4">
        <img
          :src="require('@/assets/images/marketing/iso.png')"
          class="h-20 cursor-pointer duration-500"
        />
      </div>
      <div class="hyperlinkTextColor text-sm grid gap-4">
        <div class="flex w-full justify-center gap-6">
          <a href="https://www.instagram.com/heytexperia/" target="_blank">
            <img
              :src="require('@/assets/images/marketing/icon-instagram.png')"
              class="h-4"
            />
          </a>
          <a href="https://www.facebook.com/texperia" target="_blank">
            <img
              :src="require('@/assets/images/marketing/icon-facebook.png')"
              class="h-4"
            />
          </a>
          <a href="https://linkedin.com/company/texperia" target="_blank">
            <img
              :src="require('@/assets/images/marketing/icon-linkedin.png')"
              class="h-4"
            />
          </a>
          <a
            href="https://www.youtube.com/@texperiatechsolutions7276"
            target="_blank"
          >
            <img
              :src="require('@/assets/images/marketing/icon-youtube.png')"
              class="h-4"
            />
          </a>
        </div>
        <div class="flex gap-4 justify-center">
          <div @click="openModal()">Terms & Privacy Policy</div>
          <router-link
            :to="{
              name: 'About',
            }"
            >About</router-link
          >
          <router-link
            :to="{
              name: 'Contact',
            }"
            >Contact</router-link
          >
        </div>
        <div class="text-center text-xs pb-4">
          © 2023 all rights reserved by Texperia Techsolutions Pvt. Ltd.
        </div>
      </div>
    </div>
    <PrivacyModal @close="closeModal()" v-if="privacy" />
  </div>
</template>

<script>
import PrivacyModal from "@/components/Marketing/PrivacyModal.vue";

export default {
  name: "Footer",
  components: { PrivacyModal },
  data() {
    return {
      privacy: false,
    };
  },
  methods: {
    openModal() {
      this.privacy = true;
      document.body.style.overflow = "hidden";
    },
    closeModal() {
      this.privacy = false;
      document.body.style.overflow = "visible";
    },
  },
  computed: {},
  mounted() {},
};
</script>

<style scoped>
.hyperlinkTextColor {
  color: #07003b;
}
</style>
