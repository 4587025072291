<template>
  <div
    class="fixed z-30 inset-0 overflow-y-auto"
    aria-labelledby="modal-title"
    role="dialog"
    aria-modal="true"
  >
    <div
      class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0"
    >
      <div
        @click="close()"
        class="fixed inset-0 bg-gray-800 bg-opacity-75 transition-opacity"
        aria-hidden="true"
      ></div>

      <!-- This element is to trick the browser into centering the modal contents. -->
      <span
        class="hidden sm:inline-block sm:align-middle sm:h-screen"
        aria-hidden="true"
        >&#8203;</span
      >
      <div
        class="inline-block align-bottom bg-white rounded text-left ease-in-out duration-300 overflow-hidden shadow-xl transform
      transition-all sm:my-8 sm:align-middle sm:max-w-lg md:max-w-6xl sm:w-full"
      >
        <div class="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
          <div class="sm:flex sm:items-start pb-8">
            <div class="mt-3 md:pt-6 px-6 relative">
              <h3
                class="text-3xl font-semibold pb-4 border-b border-gray-100"
                id="modal-title"
              >
                Privacy Policy
              </h3>
              <svg
                @click="close()"
                xmlns="http://www.w3.org/2000/svg"
                class="h-10 w-10 cursor-pointer text-white absolute top-0 md:top-4 right-2 md:right-4 "
                fill="none"
                viewBox="0 0 24 24"
                stroke="#ccc"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
              <div class="mt-2">
                <p class="mb-4">Effective date: April 19, 2021</p>
                <p class="mb-4">
                  This page informs you of our policies regarding the
                  collection, use, and disclosure of personal data when you use
                  our Service and the choices you have associated with that
                  data. Our Privacy Policy is managed through Free Privacy
                  Policy Website.
                </p>
                <p class="mb-8">
                  We use your data to provide and improve the Service. By using
                  the Service, you agree to the collection and use of
                  information in accordance with this policy. Unless otherwise
                  defined in this Privacy Policy, terms used in this Privacy
                  Policy have the same meanings as in our Terms and Conditions,
                  accessible from
                  <a href="https://texperia.com">https://texperia.com</a>.
                </p>
                <h3 class="font-semibold text-2xl mb-2">
                  Information Collection and Use
                </h3>
                <p class="mb-8">
                  We collect several different types of information for various
                  purposes to provide and improve our Service to you.
                </p>
                <h3 class="font-semibold text-2xl mb-2">
                  Types of Data Collected
                </h3>
                <p class="mb-6">
                  Personal Data - While using our Service, we may ask you to
                  provide us with certain personally identifiable information
                  that can be used to contact or identify you (“Personal Data”).
                  Personally, identifiable information may include, but is not
                  limited to:
                </p>
                <ul class="list-disc list-inside ml-5 mb-6">
                  <li>Email address</li>
                  <li>First name and last name</li>
                  <li>Phone number</li>
                  <li>Date of acceptance</li>
                  <li>Date of joining</li>
                  <li>Birthday (Optional)</li>
                </ul>
                <h3 class="font-semibold text-2xl mb-2">Use of Data</h3>
                <p class="mb-6">
                  Texperia on uses the collected data for various purposes:
                </p>
                <ul class="list-disc list-inside ml-5 mb-6">
                  <li>To provide and maintain the Service</li>
                  <li>To notify you about changes to our Service</li>
                  <li>
                    To allow you to participate in interactive features of our
                    Service when you choose to do so
                  </li>
                  <li>To provide customer care and support</li>
                  <li>
                    To provide analysis or valuable information so that we can
                    improve the Service
                  </li>
                  <li>To monitor the usage of the Service</li>
                  <li>To detect, prevent and address technical issues</li>
                </ul>
                <h3 class="font-semibold text-2xl mb-2">Transfer of Data</h3>
                <p class="mb-4">
                  Your information, including Personal Data, may be transferred
                  to — and maintained on — computers located outside of your
                  state, province, country or other governmental jurisdiction
                  where the data protection laws may differ than those from your
                  jurisdiction.
                </p>
                <p class="mb-4">
                  Your consent to this Privacy Policy followed by your
                  submission of such information represents your agreement to
                  that transfer.
                </p>
                <p class="mb-8">
                  Texperia will take all steps reasonably necessary to ensure
                  that your data is treated securely and in accordance with this
                  Privacy Policy and no transfer of your Personal Data will take
                  place to an organization or a country unless there are
                  adequate controls in place including the security of your data
                  and other personal information.
                </p>
                <h3 class="font-semibold text-2xl mb-8">Disclosure of Data</h3>
                <h5 class="text-xl font-semibold mb-3">Legal Requirements</h5>
                <p class="mb-6">
                  Texperia may disclose your Personal Data in the good faith
                  belief that such action is necessary to:
                </p>
                <ul class="list-disc list-inside ml-5 mb-8">
                  <li>To comply with a legal obligation</li>
                  <li>
                    To protect and defend the rights or property of Texperia.
                  </li>
                  <li>
                    To prevent or investigate possible wrongdoing in connection
                    with the Service.
                  </li>
                  <li>
                    To protect the personal safety of users of the Service or
                    the public.
                  </li>
                  <li>To protect against legal liability.</li>
                </ul>
                <h5 class="text-xl font-semibold mb-3">Security of Data</h5>
                <p class="mb-8">
                  The security of your data is important to us but remember that
                  no method of transmission over the Internet, or method of
                  electronic storage is 100% secure. While we strive to use
                  commercially acceptable means to protect your Personal Data,
                  we cannot guarantee its absolute security.
                </p>
                <h5 class="text-xl font-semibold mb-3">Analytics</h5>
                <p class="mb-8">
                  We may use third-party Service Providers to monitor and
                  analyse the use of our Service.
                </p>
                <h5 class="text-xl font-semibold mb-3">Google Analytics</h5>
                <p class="mb-4">
                  Google Analytics is a web analytics service offered by Google
                  that tracks and reports website traffic. Google uses the data
                  collected to track and monitor the use of our Service. This
                  data is shared with other Google services. Google may use the
                  collected data to contextualize and personalize the ads of its
                  own advertising network.
                </p>
                <p class="mb-4">
                  You can opt-out of having made your activity on the Service
                  available to Google Analytics by installing the Google
                  Analytics opt-out browser add-on. The add-on prevents the
                  Google Analytics JavaScript (ga.js, analytics.js, and dc.js)
                  from sharing information with Google Analytics about visits
                  activity.
                </p>
                <p class="mb-8">
                  For more information on the privacy practices of Google,
                  please visit the
                  <a
                    class="text-blue-500 underline font-medium"
                    href="https://policies.google.com/privacy?hl=en"
                    target="_blank"
                    >Google Privacy & Terms</a
                  >
                  web page.
                </p>
                <h3 class="font-semibold text-2xl mb-4">
                  Links to other sites
                </h3>
                <p class="mb-4">
                  Our Service may contain links to other sites that are not
                  operated by us. If you click on a third-party link, you will
                  be directed to that third party’s site. We strongly advise you
                  to review the Privacy Policy of every site you visit.
                </p>
                <p class="mb-4">
                  We have no control over and assume no responsibility for the
                  content, privacy policies or practices of any third-party
                  sites or services.
                </p>
                <h3 class="font-semibold text-2xl mb-4">Children's Privacy</h3>
                <p class="mb-4">
                  Our Service does not address anyone under the age of 18
                  (“Children”).
                </p>
                <p class="mb-4">
                  We do not knowingly collect personally identifiable
                  information from anyone under the age of 18. If you are a
                  parent or guardian and you are aware that your Children has
                  provided us with Personal Data, please contact us. If we
                  become aware that we have collected Personal Data from
                  children without verification of parental consent, we take
                  steps to remove that information from our servers.
                </p>
                <h3 class="font-semibold text-2xl mb-4">
                  Changes to this privacy policy
                </h3>
                <p class="mb-2">
                  We may update our Privacy Policy from time to time. We will
                  notify you of any changes by posting the new Privacy Policy on
                  this page.
                </p>
                <p class="mb-2">
                  We will let you know via email and/or a prominent notice on
                  our Service, prior to the change becoming effective and update
                  the “effective date” at the top of this Privacy Policy.
                </p>
                <p class="mb-4">
                  You are advised to review this Privacy Policy periodically for
                  any changes. Changes to this Privacy Policy are effective when
                  they are posted on this page.
                </p>
                <h3 class="font-semibold text-2xl mb-4">Contact Us</h3>
                <p class="mb-2">
                  If you have any questions about this Privacy Policy, please
                  email us at
                  <a
                    class="text-blue-500 underline"
                    href="mailto:hello@texperia.com?subject=Mail from Texperia Site"
                    >hello@texperia.com</a
                  >
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "PrivacyModal",
  methods: {
    close() {
      this.$emit("close");
    },
  },
};
</script>

<style scoped></style>
