<template>
  <div
    id="wrapper"
    :class="{
      'bg-mColorPeach': $route.name == 'Home',
      'bg-mColorCyan': $route.name == 'Features',
      metaverse: $route.name == 'Metaverse',
    }"
    class="sticky bg-white"
  >
    <div class="px-20 py-4 relative font-Gilroy">
      <div class="border-b-2 border-gray-500 flex items-center pb-2">
        <router-link
          :to="{
            name: 'Home',
          }"
          class="tracking-wider uppercase font-semibold"
        >
          <img
            :src="require('@/assets/images/marketing/logo.svg')"
            class="h-10 cursor-pointer duration-500"
          />
        </router-link>
        <div
          class="w-full flex justify-end items-center gap-8 text-sm text-black"
        >
          <router-link
            :to="{
              name: 'Features',
            }"
            :class="{
              'texperia-underline': $route.name == 'Features',
            }"
            class="tracking-wider uppercase font-semibold"
          >
            Features
          </router-link>
          <router-link
            :to="{
              name: 'About',
            }"
            :class="{
              'texperia-underline': $route.name == 'About',
            }"
            class="tracking-wider uppercase font-semibold"
          >
            About
          </router-link>
          <router-link
            :to="{
              name: 'Metaverse',
            }"
            :class="{
              'texperia-underline': $route.name == 'Metaverse',
            }"
            class="tracking-wider uppercase font-semibold"
          >
            Metaverse
          </router-link>
          <router-link
            :to="{
              name: 'Contact',
            }"
            :class="{
              'texperia-underline': $route.name == 'Contact',
            }"
            class="tracking-wider uppercase font-semibold"
          >
            Contact
          </router-link>
          <router-link
            :to="{
              name: 'Contact',
            }"
          >
            <div
              class="darkGrayBG px-6 py-1.5 uppercase text-white font-semibold rounded-lg"
            >
              book a demo
            </div>
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Header",
  components: {},
  data() {
    return {};
  },
  methods: {},
  computed: {},
  mounted() {},
};
</script>

<style scoped>
.darkGrayBG {
  background: #231e20;
}
div.sticky {
  position: -webkit-sticky;
  position: sticky;
  height: 80px;
  top: -16px;
  z-index: 1;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}
div.sticky::before,
div.sticky::after {
  content: "";
  display: block;
  height: 16px;
  /* make pseudo elements sticky as well */
  position: sticky;
  -webkit-position: sticky;
}

/* SHADOW */
div.sticky::before {
  top: 48px; /* shadow is at bottom of element, so at 48 + 16 = 64px */
}

/* COVER */
div.sticky::after {
  /* linear gradient from background color to transparent acts as
     a transition effect so the shadow appears gradually */
  top: 0;
  /* cover should fall over shadow */
  z-index: 2;
}
div.sticky > div {
  height: 64px;
  position: sticky;
  -webkit-position: sticky;
  top: 0px;
  /* compensate for shadow with negative margin */
  margin-top: -8px;
  /* content should fall over shadow and cover */
  z-index: 3;
}
.texperia-underline {
  position: relative;
}
.texperia-underline::before {
  content: "";
  position: absolute;
  top: 100%;
  width: 100%;
  left: 0;
  height: 5px;
  border-radius: 2px;
  background: linear-gradient(
    45deg,
    #11bc1d 10%,
    #3d50e0 50%,
    #fdb400 75%,
    #ff5670 100%
  );
}
.metaverse {
  background: rgba(255, 255, 255, 0.6);
  backdrop-filter: blur(2px);
}
</style>
