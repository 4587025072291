<template>
  <div
    id="wrapper"
    :class="{
      'bg-mColorPeach': $route.name == 'Home',
      'bg-mColorCyan': $route.name == 'Features',
    }"
    class="sticky bg-white"
  >
    <div class="px-4 py-4 relative font-Gilroy">
      <div
        class="border-b-2 border-gray-500 flex items-center justify-between pb-2"
      >
        <router-link
          :to="{
            name: 'Home',
          }"
          class="tracking-wider uppercase font-semibold"
        >
          <img
            :src="require('@/assets/images/marketing/logo-small.png')"
            class="h-10 cursor-pointer duration-500"
          />
        </router-link>
        <div class="flex items-center gap-4">
          <router-link
            :to="{
              name: 'Contact',
            }"
          >
            <div
              class="darkGrayBG px-6 py-1.5 uppercase text-white font-semibold rounded-lg"
            >
              book a demo
            </div>
          </router-link>
          <div id="menu" class="relative">
            <button
              :class="{ 'absolute z-50 -top-10 right-0': showSidebar }"
              class="text-black w-10 h-10 md:hidden outline-none focus:outline-none"
              @click="sideBarToggle()"
            >
              <span class="sr-only">Open menu</span>
              <div
                class="block w-5 absolute left-1/2 top-1/2 transform -translate-x-1/2 -translate-y-1/2"
              >
                <span
                  aria-hidden="true"
                  class="block absolute h-0.5 w-5 bg-current transform transition duration-500 ease-in-out"
                  :class="{
                    'rotate-45': hamburgerMenuCheckbox,
                    ' -translate-y-1.5': !hamburgerMenuCheckbox,
                  }"
                ></span>
                <span
                  aria-hidden="true"
                  class="block absolute  h-0.5 w-5 bg-current   transform transition duration-500 ease-in-out"
                  :class="{ 'opacity-0': hamburgerMenuCheckbox }"
                ></span>
                <span
                  aria-hidden="true"
                  class="block absolute  h-0.5 w-5 bg-current transform  transition duration-500 ease-in-out"
                  :class="{
                    '-rotate-45': hamburgerMenuCheckbox,
                    ' translate-y-1.5': !hamburgerMenuCheckbox,
                  }"
                ></span>
              </div>
            </button>
            <nav
              v-click-away="onSidebarClickAway"
              class="fixed -right-3/4 top-0 bottom-0 block md:hidden transition-all duration-700 ease-in-out w-3/4 z-40 sidebarContent"
              :class="{ showSidebar: showSidebar }"
            >
              <div class="h-full md:hidden shadow-2xl">
                <div class="mt-16 h-full relative">
                  <div class="grid items-center w-11/12 gap-10">
                    <div
                      class="uppercase font-GilroyBlack text-2xl border-b border-gray-900 ml-4 text-right h-12"
                    >
                      <router-link
                        :to="{
                          name: 'Features',
                        }"
                      >
                        Features
                      </router-link>
                    </div>
                    <div
                      class="uppercase font-GilroyBlack text-2xl border-b border-gray-700 ml-4 text-right h-12"
                    >
                      <router-link
                        :to="{
                          name: 'About',
                        }"
                      >
                        About
                      </router-link>
                    </div>
                    <div
                      class="uppercase font-GilroyBlack text-2xl border-b border-gray-700 ml-4 text-right h-12"
                    >
                      <router-link
                        :to="{
                          name: 'Metaverse',
                        }"
                      >
                        Metaverse
                      </router-link>
                    </div>
                    <div
                      class="uppercase font-GilroyBlack text-2xl ml-4 text-right h-12"
                    >
                      <router-link
                        :to="{
                          name: 'Contact',
                        }"
                      >
                        Contact
                      </router-link>
                    </div>
                    <div
                      class="uppercase font-Gilroy text-xl ml-4 flex items-center justify-center text-center h-12 bg-mColorDarkGray text-white rounded-lg"
                    >
                      <router-link
                        :to="{
                          name: 'Contact',
                        }"
                      >
                        Book a demo
                      </router-link>
                    </div>
                  </div>
                  <div
                    class="text-black absolute bottom-0 mb-40 border-t border-gray-900 ml-4 w-10/12"
                  >
                    <div class="flex gap-8 mx-8 mt-4">
                      <a
                        href="https://www.instagram.com/heytexperia/"
                        target="_blank"
                      >
                        <img
                          :src="
                            require('@/assets/images/marketing/icon-instagram.png')
                          "
                          class="h-4"
                        />
                      </a>
                      <a
                        href="https://www.facebook.com/texperia"
                        target="_blank"
                      >
                        <img
                          :src="
                            require('@/assets/images/marketing/icon-facebook.png')
                          "
                          class="h-4"
                        />
                      </a>
                      <a
                        href="https://linkedin.com/company/texperia"
                        target="_blank"
                      >
                        <img
                          :src="
                            require('@/assets/images/marketing/icon-linkedin.png')
                          "
                          class="h-4"
                        />
                      </a>
                      <a
                        href="https://www.youtube.com/@texperiatechsolutions7276"
                        target="_blank"
                      >
                        <img
                          :src="
                            require('@/assets/images/marketing/icon-youtube.png')
                          "
                          class="h-4"
                        />
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </nav>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mixin as VueClickAway } from "vue3-click-away";
import { VTooltip } from "v-tooltip";

export default {
  name: "Header",
  mixins: [VueClickAway],
  directives: {
    tooltip: VTooltip,
  },
  components: {},
  data() {
    return {
      showSidebar: false,
      hamburgerMenuCheckbox: false,
    };
  },
  methods: {
    sideBarToggle() {
      if (!this.menuState) {
        this.showSidebar = true;
        this.hamburgerMenuCheckbox = true;
      }
    },
    onSidebarClickAway() {
      if (this.showSidebar) {
        this.showSidebar = false;
        this.hamburgerMenuCheckbox = false;
        this.menuState = true;
      } else {
        this.menuState = false;
      }
    },
  },
  computed: {},
  mounted() {},
};
</script>

<style scoped>
.showSidebar {
  right: 0 !important;
}
.darkGrayBG {
  background: #231e20;
}
div.sticky {
  position: -webkit-sticky;
  position: sticky;
  height: 80px;
  top: -16px;
  z-index: 1;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}
div.sticky::before,
div.sticky::after {
  content: "";
  display: block;
  height: 16px;
  /* make pseudo elements sticky as well */
  position: sticky;
  -webkit-position: sticky;
}

/* SHADOW */
div.sticky::before {
  top: 48px; /* shadow is at bottom of element, so at 48 + 16 = 64px */
}

/* COVER */
div.sticky::after {
  /* linear gradient from background color to transparent acts as
     a transition effect so the shadow appears gradually */
  top: 0;
  /* cover should fall over shadow */
  z-index: 2;
}
div.sticky > div {
  height: 64px;
  position: sticky;
  -webkit-position: sticky;
  top: 0px;
  /* compensate for shadow with negative margin */
  margin-top: -8px;
  /* content should fall over shadow and cover */
  z-index: 3;
}
.texperia-underline {
  position: relative;
}
.texperia-underline::before {
  content: "";
  position: absolute;
  top: 100%;
  width: 100%;
  left: 0;
  height: 5px;
  border-radius: 2px;
  background: linear-gradient(
    45deg,
    #11bc1d 10%,
    #3d50e0 50%,
    #fdb400 75%,
    #ff5670 100%
  );
}
.sidebarContent {
  backdrop-filter: blur(15px);
}
</style>
